import React from "react"
import Layout from "../components/Layout"
import Container from "../components/Container/Container"
import { FaXTwitter } from "react-icons/fa6"

import "../assets/css/about.css"

const About = () => {
  return (
    <>
      <Layout>
        <Container style={{ color: "rgb(163, 163, 214)" }}>
          <h1 className="about-main-title">- About -</h1>
          <section>
            <h2 className="about-name">hitohira</h2>
            <p className="about-content">
              Web エンジニア.
              <br />
              セキュリティが好き.
            </p>
          </section>

          <ul className="about-links">
            <li>
              <FaXTwitter className="info-icon" />
              {` : @hitohira_t4`}
            </li>
            {/* <li>
              <div className="flex justify-center">
                <FaLink className="info-icon" />
                {` : `}
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                </a>
              </div>
            </li> */}
          </ul>
        </Container>
      </Layout>
    </>
  )
}

export default About
